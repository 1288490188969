.herobar {
    @apply bg-cover bg-center w-full;
    &-secondary {
        background-image: url('/images/okanagan-lawn.jpg');
    }
}

.herobar-hp {
    @apply bg-gray-100 text-gray-200;
    height: 30rem;
    background-image: url('/images/ok-valley-lawn-care.jpg');
    @screen md {
        height: 36rem;
    }
    @screen xl {
        height: 45rem;
    }
}

.herobar-inner {
    @apply w-full h-full pb-24 text-white bg-black-transparent-dark;
    @screen lg {
        @apply bg-black-transparent;
    }
}

.banner {
    @apply w-full h-64 bg-cover bg-center bg-no-repeat;
    height: 24rem;
    @screen xl {
        @apply bg-fixed;
    }
}
