/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@import "common";

// global styles on the most common elements
body {
    @apply font-body font-light leading-normal text-gray-800;
}

.bg-grass {
    @apply bg-bottom bg-no-repeat;
    background-image: url('/images/grass-tall.png');
    @screen xl {
        @apply bg-cover;
    }
}

.bg-pattern {
    @apply bg-green-100;
    background-image: url("https://www.transparenttextures.com/patterns/brushed-alum-dark.png");
}

// common styles
@import "alerts",
    "content_alignment",
    "forms",
    "headings",
    "modal";

// public only styles
@import "buttons",
    "content",
    "gallery",
    "grid",
    "herobar",
    "header";

// page specific
//@import ;

@tailwind utilities;
