button, a.button {
    @apply font-bold px-6 py-3 bg-green-800 text-white leading-tight transition-all duration-300 ease-in-out;
    &:hover {
        @apply bg-green-700 rounded-sm;
    }
}

a.button {
    @apply inline-block;
    &:hover {
        @apply no-underline;
    }
}
