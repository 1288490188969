.header-nav {
    @apply list-none justify-center items-center pl-0;
    @screen md {
        @apply flex flex-row;
    }
    transition: all 0.5s ease;
}

.header-nav-hp {
    @apply hidden list-none pl-0;
    @screen md {
        @apply block;
    }
}

.header-nav_item {
    @apply hidden font-display font-bold order-2 mb-3;
    text-shadow: 2px 2px 2px #000;
    transition: all 0.25s ease-in-out;
    @screen md {
        @apply inline-block mr-16 mb-0 text-xl border-b border-transparent;
        &:last-of-type {
            @apply mr-0;
        }
    }
    &:hover {
        @apply border-white;
        text-shadow: 2px 2px 2px #444;
    }
    &-sub {
        @apply hidden font-sans text-sm;
        @screen lg {
            @apply block;
        }
    }
    a {
        @apply text-white;
        &:hover {
            @apply text-gray-100 no-underline;
        }
    }
}

.header-menu {
    @apply absolute;
    top: 5.5rem;
    right: 1rem;
    @screen md {
        @apply hidden;
    }
}

.header_mobile-open {
    .header-nav {
        @apply flex flex-col text-center text-lg bg-black-transparent py-4;
    }
    .header-nav_item {
        @apply block text-lg;
        transition: all 0.5s ease-in-out;
        animation: opacity 1s 1;
    }
}

.element {
    animation: pulse 5s infinite;
}

@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
