/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
/* this file is for public and private/admin pages */
a, .button-link {
  @apply text-green-700 no-underline transition-colors duration-300 ease-in-out;
}

a:hover, a:active, .button-link:hover, .button-link:active {
  @apply underline text-green-800;
}

a:disabled, .button-link:disabled {
  @apply opacity-75;
}

a:disabled:hover, .button-link:disabled:hover {
  @apply no-underline cursor-default;
}

.button-link {
  @apply font-light;
}

ul, ol {
  @apply pl-10;
}

ul {
  @apply list-disc;
}

ol {
  @apply list-decimal;
}

address {
  @apply not-italic;
}

td {
  @apply align-top;
}

.transition {
  transition: all 0.25s ease;
}

body {
  @apply font-body font-light leading-normal text-gray-800;
}

.bg-grass {
  @apply bg-bottom bg-no-repeat;
  background-image: url("/images/grass-tall.png");
}

@screen xl {
  .bg-grass {
    @apply bg-cover;
  }
}

.bg-pattern {
  @apply bg-green-100;
  background-image: url("https://www.transparenttextures.com/patterns/brushed-alum-dark.png");
}

.alert {
  @apply flex justify-between mt-0 mx-auto mb-1 p-2 bg-gray-200 border-l-10;
}

.form-wrap .alert {
  @apply w-full;
}

.alert-success {
  @apply border-green-500;
}

.alert-info {
  @apply border-blue-400;
}

.alert-warning {
  @apply border-orange-500;
}

.alert-danger {
  @apply border-red-500;
}

.content-img-center {
  @apply block mx-auto my-4;
}

.content-img-left {
  @apply float-left my-4 mr-4;
}

.content-img-right {
  @apply float-right my-4 ml-4;
}

[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=number],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea {
  @apply w-full px-2 py-1 text-black border border-gray-400 rounded-sm bg-gray-200 transition-colors duration-300 ease-in-out;
}

[type=text]:focus,
[type=password]:focus,
[type=date]:focus,
[type=datetime]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=week]:focus,
[type=email]:focus,
[type=number]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=url]:focus,
[type=color]:focus,
textarea:focus {
  @apply border-gray-500 bg-gray-100;
}

textarea {
  @apply align-top text-black;
}

select {
  @apply w-full p-1 text-black border border-gray-400 rounded-sm bg-gray-200 transition-colors duration-300 ease-in-out;
  height: 2.14rem;
}

select:focus {
  @apply border-gray-500 bg-gray-100;
}

[type=checkbox] {
  @apply mr-2;
}

[type=radio] {
  @apply relative mr-1;
  top: -1px;
}

[type=submit], [type=image], label, select {
  @apply cursor-pointer;
}

[type=file] {
  width: 100%;
}

.form-wrap {
  @apply w-11/12 max-w-lg mx-auto my-4 p-4 rounded-sm shadow-md;
}

.field-wrap {
  @apply mb-4;
}

.field-wrap label {
  @apply block mb-1;
}

.field-wrap-checkbox {
  @apply flex flex-wrap items-center mb-4;
}

.field-wrap-checkbox label {
  @apply inline-block mr-4 mb-0;
}

.field-wrap-radio {
  @apply mb-1;
}

.field-wrap-radios, .field-wrap-checkboxes {
  @apply mb-4;
}

.field-wrap-radios label, .field-wrap-checkboxes label {
  @apply inline-block mr-4 mb-0;
}

.field-wrap-radios legend, .field-wrap-checkboxes legend {
  @apply mb-1;
}

.field-wrap-radios .field-wrap-checkbox, .field-wrap-checkboxes .field-wrap-checkbox {
  @apply mb-1;
}

.field-errors {
  @apply mb-1 pl-0 list-none;
}

.field-errors li {
  @apply mb-1 p-2 bg-gray-200 border-l-10 border-red-700;
}

.field-wrap-readonly {
  @apply relative;
}

.field-wrap-readonly input {
  @apply opacity-50;
}

.field-wrap-readonly:after {
  @apply absolute right-0 text-xs opacity-50;
  top: 0.5rem;
  content: "read-only";
}

.field-help {
  @apply w-full mt-px text-xs italic text-gray-600 leading-tight;
  padding-left: 0.1em;
}

.form-action {
  @apply text-sm ml-4;
}

h1, h2, h3, h4, h5, h6 {
  @apply leading-tight font-bold;
}

h1 {
  @apply text-3xl;
  margin: 0.67rem 0;
}

h2 {
  @apply text-2xl;
  margin: 0.83rem 0;
}

h3 {
  @apply text-xl mt-6 mb-2;
}

h4 {
  @apply mt-6 mb-2;
}

h5 {
  @apply text-sm;
  margin: 1.67rem 0;
}

h6 {
  @apply text-xs mt-6 mb-2;
}

h3 + p, h4 + p {
  @apply mt-0;
}

h1 a, h2 a, h3 a, h4 a {
  @apply no-underline text-inherit;
}

h1 a:hover, h1 a:active, h2 a:hover, h2 a:active, h3 a:hover, h3 a:active, h4 a:hover, h4 a:active {
  @apply underline text-inherit;
}

p {
  @apply my-4;
}

.md-enter-active,
.md-leave-active {
  @apply transition-all duration-500;
}

.md-enter,
.md-leave-active {
  @apply opacity-0;
  transform: translateY(24px);
}

button, a.button {
  @apply font-bold px-6 py-3 bg-green-800 text-white leading-tight transition-all duration-300 ease-in-out;
}

button:hover, a.button:hover {
  @apply bg-green-700 rounded-sm;
}

a.button {
  @apply inline-block;
}

a.button:hover {
  @apply no-underline;
}

.w-content, .w-content-narrow, .w-content-copy {
  @apply max-w-11/12 mx-auto;
}

@screen xl {
  .w-content {
    @apply max-w-6xl;
  }
}

@screen md {
  .w-content-narrow {
    @apply max-w-lg;
  }
}

@screen xl {
  .w-content-narrow {
    @apply max-w-4xl;
  }
}

@screen md {
  .w-content-copy {
    @apply max-w-md;
  }
}

@screen xl {
  .w-content-copy {
    @apply max-w-xl;
  }
}

#silentbox-group {
  @apply text-center;
}

#silentbox-overlay {
  z-index: 9999 !important;
}

.silentbox-item {
  @apply inline-block overflow-hidden;
}

.silentbox-item img {
  @apply transition-all duration-500 ease-in-out;
}

.silentbox-item:hover {
  @apply overflow-hidden;
}

.silentbox-item:hover img {
  transform: scale(1.05);
}

.grid {
  @apply flex flex-wrap;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-auto-rows: minmax(24rem, auto);
  @apply justify-center max-w-sm mx-auto;
}

@screen md {
  .grid {
    @apply max-w-xl;
  }
}

@screen xl {
  .grid {
    @apply max-w-4xl;
  }
}

@screen max {
  .grid {
    @apply justify-between max-w-6xl;
  }
}

.grid-box {
  @apply relative flex flex-col w-full mx-auto border-b-8 border-green-900 bg-white shadow-md transition-all duration-500 ease-out;
}

.grid-box:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.6);
  @apply no-underline;
}

@screen sm {
  .grid-box {
    @apply mr-4 mb-8 ml-0;
    width: 31%;
  }
}

@supports (display: grid) {
  @screen sm {
    .grid-box {
      @apply w-auto mx-0 mb-0;
    }
  }
}

.grid-box-inner {
  @apply flex justify-center items-end h-full text-white uppercase bg-black-transparent-dark transition-all duration-300 ease-in-out;
}

@screen lg {
  .grid-box-inner {
    @apply bg-transparent;
  }
}

.grid-box-inner:hover {
  @apply bg-black-transparent-dark;
}

.grid-heading {
  @apply mb-8 text-3xl font-normal;
}

.herobar {
  @apply bg-cover bg-center w-full;
}

.herobar-secondary {
  background-image: url("/images/okanagan-lawn.jpg");
}

.herobar-hp {
  @apply bg-gray-100 text-gray-200;
  height: 30rem;
  background-image: url("/images/ok-valley-lawn-care.jpg");
}

@screen md {
  .herobar-hp {
    height: 36rem;
  }
}

@screen xl {
  .herobar-hp {
    height: 45rem;
  }
}

.herobar-inner {
  @apply w-full h-full pb-24 text-white bg-black-transparent-dark;
}

@screen lg {
  .herobar-inner {
    @apply bg-black-transparent;
  }
}

.banner {
  @apply w-full h-64 bg-cover bg-center bg-no-repeat;
  height: 24rem;
}

@screen xl {
  .banner {
    @apply bg-fixed;
  }
}

.header-nav {
  @apply list-none justify-center items-center pl-0;
  transition: all 0.5s ease;
}

@screen md {
  .header-nav {
    @apply flex flex-row;
  }
}

.header-nav-hp {
  @apply hidden list-none pl-0;
}

@screen md {
  .header-nav-hp {
    @apply block;
  }
}

.header-nav_item {
  @apply hidden font-display font-bold order-2 mb-3;
  text-shadow: 2px 2px 2px #000;
  transition: all 0.25s ease-in-out;
}

@screen md {
  .header-nav_item {
    @apply inline-block mr-16 mb-0 text-xl border-b border-transparent;
  }
  .header-nav_item:last-of-type {
    @apply mr-0;
  }
}

.header-nav_item:hover {
  @apply border-white;
  text-shadow: 2px 2px 2px #444;
}

.header-nav_item-sub {
  @apply hidden font-sans text-sm;
}

@screen lg {
  .header-nav_item-sub {
    @apply block;
  }
}

.header-nav_item a {
  @apply text-white;
}

.header-nav_item a:hover {
  @apply text-gray-100 no-underline;
}

.header-menu {
  @apply absolute;
  top: 5.5rem;
  right: 1rem;
}

@screen md {
  .header-menu {
    @apply hidden;
  }
}

.header_mobile-open .header-nav {
  @apply flex flex-col text-center text-lg bg-black-transparent py-4;
}

.header_mobile-open .header-nav_item {
  @apply block text-lg;
  transition: all 0.5s ease-in-out;
  animation: opacity 1s 1;
}

.element {
  animation: pulse 5s infinite;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@tailwind utilities;
