#silentbox-group {
    @apply text-center;
}

#silentbox-overlay {
    z-index: 9999 !important;
}

.silentbox-item {
    @apply inline-block overflow-hidden;

    img {
        @apply transition-all duration-500 ease-in-out;
    }
    &:hover {
        @apply overflow-hidden;
        img {
            transform: scale(1.05);
        }
    }
}
