.grid {
    @apply flex flex-wrap;
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    grid-auto-rows: minmax(24rem, auto);
    @apply justify-center max-w-sm mx-auto;
    @screen md {
        @apply max-w-xl;
    }
    @screen xl {
        @apply max-w-4xl;
    }
    @screen max {
        @apply justify-between max-w-6xl;
    }
}

.grid-box {
    @apply relative flex flex-col w-full mx-auto border-b-8 border-green-900 bg-white shadow-md transition-all duration-500 ease-out;
    &:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.6);
        @apply no-underline;
    }
    @screen sm {
        @apply mr-4 mb-8 ml-0;
        width: 31%;
    }

    @supports (display:grid) {
        @screen sm {
            @apply w-auto mx-0 mb-0;
        }
    }
}

.grid-box-inner {
    @apply flex justify-center items-end h-full text-white uppercase bg-black-transparent-dark transition-all duration-300 ease-in-out;
    @screen lg {
        @apply bg-transparent;
    }
    &:hover {
        @apply bg-black-transparent-dark;
    }
}

.grid-heading {
    @apply mb-8 text-3xl font-normal;
}
