/* this file is for public and private/admin pages */

a, .button-link {
    @apply text-green-700 no-underline transition-colors duration-300 ease-in-out;
    &:hover, &:active {
        @apply underline text-green-800;
    }
    &:disabled {
        @apply opacity-75;
        &:hover {
            @apply no-underline cursor-default;
        }
    }
}

.button-link {
    @apply font-light;
}

//re-add list styles as tailwind removes them
ul, ol {
    @apply pl-10;
}
ul {
    @apply list-disc;
}
ol {
    @apply list-decimal;
}

address {
    @apply not-italic;
}

td {
    @apply align-top;
}

.transition {
    transition: all 0.25s ease;
}
